<template lang="pug">
  div
    template(v-if="!loading")
      v-card.mb-2(
        v-for="(item, index) in reports"
        :key="index")
        .list-1
          .list-1__item
            .list-1__item-label {{ 'base.recipe' | translate }}
            .list-1__item-value {{ item.recipe_name }}
          .list-1__item
            .list-1__item-label {{ 'base.weight' | translate }} [{{ 'units.kg' | translate }}] {{ 'base.plan' | translate }} / {{ 'base.fact' | translate }}
            .list-1__item-value {{ item.plan_weight }} / {{ item.fact_weight }}
          .list-1__item
            .list-1__item-label {{ 'pages.reports.load_time' | translate }} [{{ 'units.m' | translate }}]
            .list-1__item-value {{ item.load_time || 0 }}
          .list-1__item
            .list-1__item-label {{ 'base.status' | translate }}
            .list-1__item-value
              span(:style="{ color: statuses[item.status].color }")
                | {{ statuses[item.status].name | translate }}
          .list-1__item
            .list-1__item-label {{ 'base.date' | translate }}
            .list-1__item-value {{ item.date | date }}

      v-table-body(
        v-if="!loading"
        :outsideTable="true"
        :empty="!reports.length")

    template(v-else)
      v-card.mb-2(v-for="i in 5" :key="i")
        .list-1
          .list-1__item(v-for="j in 5" :key="`${i}-${j}`")
            .list-1__item-skeleton

    template(
      v-if="!loading && showPagination")
      v-paginate(
        v-model="page"
        :page-count="pageCount"
        :click-handler="pageChangeHandler")

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { PLANNING_STATUSES } from '@/util/consts.js'

import paginationMixin from '@/mixins/pagination.mixin'

export default {
  name: 'ReportsTableMobile',

  mixins: [paginationMixin],

  data: () => ({
    statuses: PLANNING_STATUSES
  }),

  computed: {
    ...mapGetters([
      'reports'
    ])
  },

  mounted () {
    this.setupPagination(this.fetchReports)
  },

  methods: {
    ...mapActions(['fetchReports'])
  }
}
</script>

<style lang="scss">
</style>
